import React, { useState, useEffect } from 'react';
import { Input, Button, Tree, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { DataNode } from 'antd/lib/tree';

interface FilterItem {
  id: string;
  name: string;
  text: string;
  title: string;
  value: string;
  children?: FilterItem[];
}

interface SearchFilterProps {
  selectedKeys: string | string[];
  setSelectedKeys: (keys: string | string[]) => void;
  confirm: () => void;
  clearFilters?: () => void;
  uniqueCount?: number;
  filters?: FilterItem[];
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
  uniqueCount,
  filters,
}) => {
  // State for the search input value
  const [searchValue, setSearchValue] = useState<string>('');

  // State for tree search filtering
  const [treeSearchValue, setTreeSearchValue] = useState<string>('');

  // State for tree checked keys (now using checked instead of selected)
  const [treeCheckedKeys, setTreeCheckedKeys] = useState<string[]>([]);

  // State to track which filter type is active
  const [activeFilterType, setActiveFilterType] = useState<'search' | 'tree'>(
    Array.isArray(selectedKeys) ? 'tree' : 'search'
  );

  // Update component state when selectedKeys changes from outside
  useEffect(() => {
    if (selectedKeys) {
      if (Array.isArray(selectedKeys) && selectedKeys.length > 0) {
        // Tree selection mode
        setTreeCheckedKeys(selectedKeys);
        setSearchValue('');
        setActiveFilterType('tree');
      } else if (typeof selectedKeys === 'string' && selectedKeys !== '') {
        // Search mode
        setSearchValue(selectedKeys);
        setTreeCheckedKeys([]);
        setActiveFilterType('search');
      }
    } else {
      // Reset both
      setSearchValue('');
      setTreeCheckedKeys([]);
    }
  }, [selectedKeys]);

  // Convert filters to tree data format
  const treeData: DataNode[] = React.useMemo(() => {
    if (!filters) return [];

    return filters.map((item) => ({
      key: item.value,
      title: item.text || item.name || item.title,
    }));
  }, [filters]);

  // Filter tree nodes based on search
  const filteredTreeData = React.useMemo(() => {
    if (!treeSearchValue.trim()) return treeData;

    return treeData.filter((node) =>
      String(node.title).toLowerCase().includes(treeSearchValue.toLowerCase())
    );
  }, [treeData, treeSearchValue]);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value) {
      // If using search, clear tree selection
      setTreeCheckedKeys([]);
      setActiveFilterType('search');
      // For search, pass the string value directly, not in an array
      setSelectedKeys(value);
    } else {
      // When clearing the search, set selectedKeys to null/empty
      setSelectedKeys('');
    }
  };

  // Handle tree check (replaces the select handler)
  const handleTreeCheck = (
    checkedKeys:
      | React.Key[]
      | { checked: React.Key[]; halfChecked: React.Key[] }
  ) => {
    // Convert to string array (handle both string[] and object with checked property)
    const keys = Array.isArray(checkedKeys) ? checkedKeys : checkedKeys.checked;

    const stringKeys = keys.map((k) => String(k));
    setTreeCheckedKeys(stringKeys);

    if (stringKeys.length > 0) {
      // If using tree, clear search input
      setSearchValue('');
      setActiveFilterType('tree');
      // For tree selection, pass the array of keys
      setSelectedKeys(stringKeys);
    } else {
      // When no tree items are selected, set selectedKeys to empty array
      setSelectedKeys([]);
    }
  };

  // Handle tree search input change
  const handleTreeSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTreeSearchValue(e.target.value);
  };

  // Handle search button click
  const handleSearch = () => {
    confirm();
  };

  // Handle reset button click
  const handleReset = () => {
    setSearchValue('');
    setTreeCheckedKeys([]);
    setTreeSearchValue('');

    if (clearFilters) {
      clearFilters();
    } else {
      // Reset based on active filter type
      if (activeFilterType === 'search') {
        setSelectedKeys('');
      } else {
        setSelectedKeys([]);
      }
    }

    confirm();
  };

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      {/* Search input */}
      <Input
        placeholder="Hledat text..."
        value={searchValue}
        onChange={handleSearchChange}
        style={{ width: '100%', marginBottom: 8 }}
        disabled={activeFilterType === 'tree' && treeCheckedKeys.length > 0}
      />

      {/* Show tree section only if filters are provided */}
      {filters && filters.length > 0 && (
        <>
          <Divider style={{ margin: '8px 0' }}>nebo</Divider>

          {/* Tree search input */}
          <Input
            placeholder="Vyhledat ve filtrech"
            value={treeSearchValue}
            onChange={handleTreeSearchChange}
            style={{ width: '100%', marginBottom: 8 }}
          />

          {/* Checkbox Tree */}
          <div style={{ maxHeight: 264, overflow: 'auto', marginBottom: 8 }}>
            <Tree
              className="search-filter-tree"
              checkable
              treeData={filteredTreeData}
              checkedKeys={treeCheckedKeys}
              onCheck={handleTreeCheck}
              blockNode
              selectable={false}
              disabled={activeFilterType === 'search' && searchValue.length > 0}
            />
          </div>
        </>
      )}

      {/* Unique count info if provided */}
      {uniqueCount !== undefined && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 8,
          }}
        >
          Počet unikátních hodnot: {uniqueCount}
        </div>
      )}

      {/* Action buttons */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 8,
        }}
      >
        <Button onClick={handleReset} size="small">
          Zrušit
        </Button>
        <Button
          type="primary"
          onClick={handleSearch}
          icon={<SearchOutlined />}
          size="small"
        >
          Vyhledat
        </Button>
      </div>
    </div>
  );
};

export default SearchFilter;
