import moment from 'moment';
import { DatePicker, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { toStandardDate } from '@utils';

const DateRangeFilter = ({ selectedKeys, setSelectedKeys, confirm }: any) => {
  const handleSearch = () => {
    confirm();
  };

  const value =
    selectedKeys.length > 0
      ? selectedKeys
          .split('__')
          .map((standard_date: string) => moment(standard_date))
      : '';

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <DatePicker.RangePicker
        value={value}
        onChange={(range) => {
          if (range) {
            setSelectedKeys(
              `${toStandardDate(range[0])}__${toStandardDate(range[1])}`
            );
          } else {
            setSelectedKeys('');
          }
        }}
        format="DD. MM. YYYY"
        style={{ width: 230 }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 8,
        }}
      >
        <Button
          onClick={() => {
            setSelectedKeys('');
            handleSearch();
          }}
          size="small"
        >
          Zrušit
        </Button>
        <Button
          type="primary"
          onClick={() => handleSearch()}
          icon={<SearchOutlined />}
          size="small"
        >
          Vyhledat
        </Button>
      </div>
    </div>
  );
};

export default DateRangeFilter;
