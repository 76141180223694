import { useState } from 'react';
// import moment from 'moment';
import { message, Form, Button, Card } from 'antd';

import { FormField, RoadTestsFileList } from '@components';

import { useDebouncedCallback, defaultDebounceTime } from '@hooks';

import {
  getOptions,
  requiredRule,
  maxRule,
  // dateAfterRule,
} from '@utils';

// import { getContractorDataSource } from './requests';

const getNumberOfExpectedAttachments = (
  row: any,
  allowProtocolCirculation?: boolean
) => {
  const { entry_source, from_sample } = row;

  const reqAttachments = allowProtocolCirculation ? 0 : 1;
  const expected =
    entry_source === 'mobile'
      ? from_sample
        ? reqAttachments
        : reqAttachments + 1
      : reqAttachments;
  return expected;
};

const EditRoadTestForm = ({
  // constructionId,
  activeEntry,
  setModeToNull,
  allowProtocolCirculation,
  permittedUserActions,
  freeMode,
  editableColumns,
  initialValues,
  onFormSave,
  onFormFinish,
  tableOptions,
}: any) => {
  const [form] = Form.useForm();
  const [attachments, setAttachments] = useState(
    initialValues.attachments || []
  );
  // const [contractorOptions, setContractorOptions] = useState([]);
  const [finishError, setFinishError] = useState(false);

  const getPayload = () => {
    const fieldValues = form.getFieldsValue();
    const payload = {
      ...fieldValues,
      add_attachment_ids: attachments.map((file: RoadTestsFile) => file.id),
      author:
        typeof fieldValues.author === 'number'
          ? fieldValues.author
          : tableOptions.possible_author.filter(
              (option: any) => option.name.indexOf(fieldValues.author) > -1
            )[0]?.id || undefined,
    };
    // if (!payload.contractor) delete payload.contractor;
    return payload;
  };

  const performUpdate = async () => {
    try {
      await form.validateFields();
      onFormSave(getPayload());
    } catch (error) {
      console.error(error);
      message.error('Opravte prosím chyby ve formuláři');
    }
  };

  const performDebouncedUpdate = useDebouncedCallback(() => {
    performUpdate();
  }, defaultDebounceTime);

  const onFinish = async () => {
    try {
      await form.validateFields();

      const payload = getPayload();
      const requiredFields = [
        'contractor_text',
        'laboratory',
        'construction_part_specification',
        'date',
        'protocol_number',
        'measured_value',
        'units',
        'desired_value',
        'evaluation',
      ];

      for (const field of requiredFields) {
        if (field in payload && !payload[field]) {
          setFinishError(true);
          form.validateFields();
          return message.error(
            'Zkouška nebyla uzavřena, je nutné vyplnit všechna povinná pole'
          );
        }
      }

      if (
        attachments.length <
        getNumberOfExpectedAttachments(activeEntry, allowProtocolCirculation)
      ) {
        return message.error(
          'Zkouška nebyla uzavřena, je nutné přidat přílohy'
        );
      }

      onFormFinish(payload);
    } catch (error) {
      console.error(error);
      message.error('Opravte prosím chyby ve formuláři');
    }
  };

  return (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <Card>
        <Form
          form={form}
          autoComplete="off"
          initialValues={initialValues}
          onValuesChange={() => {
            performDebouncedUpdate();
          }}
          style={{ width: '100%' }}
        >
          <div className="roadtest-field-container">
            {editableColumns.map((col: string) => {
              switch (col) {
                // case 'contractor':
                //   return (
                //     <Form.Item
                //       key={col}
                //       className="form-item"
                //       name="contractor"
                //       rules={finishError ? [requiredRule] : []}
                //     >
                //       <FormField
                //         type="select"
                //         placeholder="Objednatel *"
                //         options={contractorOptions}
                //         onSearch={(contractorValue: string) => {
                //           if (contractorValue) {
                //             getContractorDataSource(
                //               constructionId,
                //               contractorValue
                //             ).then(({ data }) => {
                //               setContractorOptions(
                //                 getOptions(data.results, 'title', 'title')
                //               );
                //               form.setFieldsValue({
                //                 contractor: contractorValue,
                //               });
                //               performDebouncedUpdate();
                //             });
                //           }
                //         }}
                //       />
                //     </Form.Item>
                //   );
                case 'contractor_text':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="contractor_text"
                      rules={
                        finishError
                          ? [maxRule(100), requiredRule]
                          : [maxRule(100)]
                      }
                    >
                      <FormField type="text" placeholder="Objednatel *" />
                    </Form.Item>
                  );
                case 'technology_part':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="technology_part"
                    >
                      <FormField
                        type="select"
                        placeholder="Část technologického celku"
                        options={getOptions(
                          tableOptions.technology_part.filter(
                            (option: any) =>
                              option.parent === initialValues.construction_part
                          )
                        )}
                      />
                    </Form.Item>
                  );
                case 'protocol_number':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="protocol_number"
                      rules={
                        finishError
                          ? [maxRule(24), requiredRule]
                          : [maxRule(24)]
                      }
                    >
                      <FormField type="text" placeholder="Číslo protokolu *" />
                    </Form.Item>
                  );
                case 'handover_protocol_number':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="handover_protocol_number"
                    >
                      <FormField
                        type="text"
                        placeholder="Číslo předávacího protokolu"
                      />
                    </Form.Item>
                  );
                case 'construction_part_specification':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="construction_part_specification"
                      rules={
                        finishError
                          ? [maxRule(250), requiredRule]
                          : [maxRule(250)]
                      }
                    >
                      <FormField
                        type="text"
                        placeholder="Specifikace zk.místa, druh mat... *"
                        label={
                          <span title="Specifikace zk.místa, druh materiálu, asfaltové směsi *">
                            Specifikace zk.místa, druh materiálu... *
                          </span>
                        }
                      />
                    </Form.Item>
                  );
                case 'from_axis':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="from_axis"
                      rules={[maxRule(24)]}
                    >
                      <FormField
                        type="text"
                        placeholder="Od osy (L,P,m), (x/y/z)..."
                        label={
                          <span title="Od osy (L,P,m), (x/y/z), (vrstva)">
                            Od osy (L,P,m), (x/y/z)...
                          </span>
                        }
                      />
                    </Form.Item>
                  );
                case 'delivery_note_num':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="delivery_note_num"
                    >
                      <FormField
                        type="text"
                        placeholder="Číslo dodacího listu"
                      />
                    </Form.Item>
                  );
                case 'measured_value':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="measured_value"
                      rules={
                        finishError
                          ? [maxRule(24), requiredRule]
                          : [maxRule(24)]
                      }
                    >
                      <FormField
                        type="text"
                        placeholder="Hlavní naměřená hodnota *"
                      />
                    </Form.Item>
                  );
                case 'units':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="units"
                      rules={finishError ? [requiredRule] : []}
                    >
                      <FormField
                        type="select"
                        placeholder="Jednotky *"
                        options={getOptions(tableOptions.units)}
                      />
                    </Form.Item>
                  );
                case 'measured_value_1':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="measured_value_1"
                    >
                      <FormField type="text" placeholder="Dílčí hodnota 1" />
                    </Form.Item>
                  );
                case 'units_1':
                  return (
                    <Form.Item key={col} className="form-item" name="units_1">
                      <FormField
                        type="select"
                        placeholder="Jednotky 1"
                        options={getOptions(tableOptions.units)}
                      />
                    </Form.Item>
                  );
                case 'measured_value_2':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="measured_value_2"
                    >
                      <FormField type="text" placeholder="Dílčí hodnota 2" />
                    </Form.Item>
                  );
                case 'units_2':
                  return (
                    <Form.Item key={col} className="form-item" name="units_2">
                      <FormField
                        type="select"
                        placeholder="Jednotky 2"
                        options={getOptions(tableOptions.units)}
                      />
                    </Form.Item>
                  );
                case 'measured_value_3':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="measured_value_3"
                    >
                      <FormField type="text" placeholder="Četnost celkem" />
                    </Form.Item>
                  );
                case 'units_3':
                  return (
                    <Form.Item key={col} className="form-item" name="units_3">
                      <FormField
                        type="text"
                        placeholder="Počet nevyhovujících"
                      />
                    </Form.Item>
                  );
                case 'desired_value':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="desired_value"
                      rules={
                        finishError
                          ? [maxRule(24), requiredRule]
                          : [maxRule(24)]
                      }
                    >
                      <FormField
                        type="text"
                        placeholder="Požadováná hodnota *"
                      />
                    </Form.Item>
                  );
                case 'datum_betonaze':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="datum_betonaze"
                    >
                      <FormField type="date" placeholder="Datum betonáže" />
                    </Form.Item>
                  );
                case 'trida_betonu':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="trida_betonu"
                    >
                      <FormField type="text" placeholder="Třída betonu" />
                    </Form.Item>
                  );
                case 'receptura_betonu':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="receptura_betonu"
                    >
                      <FormField type="text" placeholder="Receptura betonu" />
                    </Form.Item>
                  );
                case 'evaluation':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="evaluation"
                      rules={finishError ? [requiredRule] : []}
                    >
                      <FormField
                        type="select"
                        placeholder="Závěr V/N *"
                        options={getOptions(tableOptions.evaluation)}
                      />
                    </Form.Item>
                  );
                case 'note':
                  return (
                    <Form.Item key={col} className="form-item" name="note">
                      <FormField type="text" placeholder="Poznámka" />
                    </Form.Item>
                  );
                case 'author':
                  return (
                    <Form.Item key={col} className="form-item" name="author">
                      <FormField
                        type="select"
                        placeholder="Zkoušku provedl"
                        options={getOptions(tableOptions.possible_author)}
                        readOnly={
                          !freeMode &&
                          !permittedUserActions.can_edit_extra_fields
                        }
                      />
                    </Form.Item>
                  );
                case 'confirmation_time':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="confirmation_time"
                      // rules={
                      //   !freeMode
                      //     ? [dateAfterRule(activeEntry.time_create)]
                      //     : []
                      // }
                    >
                      <FormField
                        type="date"
                        placeholder="Datum odevzdání"
                        readOnly={
                          !freeMode &&
                          !permittedUserActions.can_edit_extra_fields
                        }
                      />
                    </Form.Item>
                  );
                case 'laboratory':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="laboratory"
                      rules={finishError ? [requiredRule] : []}
                    >
                      <FormField
                        type="select"
                        placeholder="Provedl (AZL, OZ) *"
                        options={getOptions(tableOptions.laboratory)}
                      />
                    </Form.Item>
                  );
                case 'date':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="date"
                      rules={finishError ? [requiredRule] : []}
                    >
                      <FormField
                        type="date"
                        placeholder="Datum zkoušky / odběru *"
                      />
                    </Form.Item>
                  );
                case 'esd_record':
                  return (
                    <Form.Item
                      key={col}
                      className="form-item"
                      name="esd_record"
                    >
                      <FormField type="checkbox" placeholder="Záznam pro ESD" />
                    </Form.Item>
                  );
                default:
                  return null;
              }
            })}
          </div>
          <RoadTestsFileList
            label="Přílohy"
            files={attachments}
            onChange={(files: RoadTestsFile[]) => {
              setAttachments(files);
              performDebouncedUpdate();
            }}
          />
        </Form>
      </Card>
      <div style={{ marginLeft: 15, textAlign: 'center' }}>
        <Button
          className="green-button"
          type="primary"
          shape="round"
          onClick={() => onFinish()}
        >
          Uzavřít zkoušku
        </Button>
        <Button
          type="text"
          shape="round"
          onClick={() => setModeToNull()}
          style={{ marginTop: 10 }}
        >
          <b>Zavřít editační okno</b>
        </Button>
      </div>
    </div>
  );
};

export default EditRoadTestForm;
