export const mapOptionsToData = (data, options) => {
  const optionsKeys = Object.keys(options);
  return data.map((row) => {
    return Object.keys(row).reduce((mappedRow, key) => {
      if (row[key] && optionsKeys.indexOf(key) !== -1) {
        const optionObject = options[key].filter((e) => e.id === row[key])[0];
        return {
          ...mappedRow,
          [key]: optionObject ? optionObject : row[key],
        };
      }
      return { ...mappedRow, [key]: row[key] };
    }, {});
  });
};

export const mapOptionsToRoadtests = (data, options) => {
  return mapOptionsToData(data, options).map((row) => {
    if (typeof row.construction_test === 'number') {
      const target = options.sampling_type.filter(
        (option) => option.id === row.construction_test
      )[0];
      if (target) row.construction_test = target;
    }

    return row;
  });
};

export const mapOptionsToOrders = (data, options) => {
  return mapOptionsToData(data, options).map((row) => {
    if (typeof row.construction_test === 'number') {
      const target = options.sampling_type.filter(
        (option) => option.id === row.construction_test
      )[0];
      if (target) row.construction_test = target;
    }

    return row;
  });
};

export const mapOptionsToMailRecords = (data, options) => {
  return mapOptionsToData(data, options).map((row) => {
    const recipients = [];

    row.recipients.forEach((recipient: number) => {
      const target = options.recipients.filter(
        (option) => option.id === recipient
      )[0];
      if (target) recipients.push(target);
    });

    row.recipients = recipients;
    return row;
  });
};

export const getFilterQuery = (filters: any) => {
  let query = '';
  for (let key in filters || {}) {
    if (Array.isArray(filters[key]) && filters[key].length > 0) {
      if (key === 'construction_part_specification') {
        query += `${key}s=${encodeURIComponent(filters[key].join('|||'))}&`;
      } else {
        query += `${key}=${encodeURIComponent(filters[key].join(','))}&`;
      }
    } else if (filters[key]) {
      query += `${key}=${encodeURIComponent(filters[key])}&`;
    }
  }
  return query;
};

export const highlightTableRow = (event: any, id: any) => {
  if (['path', 'svg'].indexOf(event.target.tagName) > -1) {
    return;
  }

  if (event.target.classList.contains('no-highlight')) {
    return;
  }

  const previousHighlighted = document.getElementsByClassName(
    'table-row-highlight'
  );
  const target = document.getElementsByClassName(`table-row-${id}`);
  const isTheSame = previousHighlighted?.[0]?.isEqualNode(target?.[0]);

  for (let element of previousHighlighted) {
    element.classList.remove('table-row-highlight');
  }

  if (!isTheSame) {
    for (let element of target) {
      element.classList.add('table-row-highlight');
    }
  }
};
