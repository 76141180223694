import { request } from '@utils';

export const createExportFile = (
  id: string,
  query: string,
  type: string
): Promise<{ link: string }> => {
  const url = `/api/constructions/${id}/entries/export/?fileType=${type}&${query}`;
  return request(url, { method: 'post' });
};

export const createExportPDFFile = (
  id: string,
  query: string
): Promise<{ link: string }> => {
  const url = `/api/constructions/${id}/entries/export-pdf/?${query}`;
  return request(url, { method: 'post' });
};

export const downloadAttachments = (
  id: string,
  query: string
): Promise<{ link: string }> => {
  const url = `/api/constructions/${id}/entries/download-attachments/?${query}`;
  return request(url, { method: 'get' });
};

export const saveTableConfig = (data) => {
  const url = '/api/constructions/table-config';
  return request(url, {
    method: 'put',
    data,
  }).then((response) => {
    return { ok: response.data ? response.data.ok : false };
  });
};

export const fetchInitialData = () => {
  const url = '/api/constructions/initial-data';
  return request(url).then((_) => _.data);
};

export const getTables = () => request('/api/constructions?desktop=1');

export const getTableRows = (id, query = '') =>
  request('/api/constructions/' + id + '/entries/?' + query).then(
    (res) => res.data
  );

export const getDynamicTableData = (id, query = '') =>
  request('/api/constructions/' + id + '/dynamic-data/?' + query).then(
    (res) => res.data
  );

export const getTableOptions = (id) =>
  request(
    '/api/constructions/' + id + '/tableopts?activity_type=zkouska_v_terenu'
  ).then((response) => ({
    ...response.data,
    // everything else is singular, so add duplicate field for convenience
    stationing_format: response.data.stationing_formats,
  }));

export const saveActiveRowValues = (
  tableid,
  rowid,
  data,
  { isMassEdit } = {}
) => {
  let endpoint = `/api/constructions/${tableid}/entries/${rowid}?`;
  if (isMassEdit) endpoint += '&mass_edit=true';

  return request(endpoint, {
    data,
    method: 'patch',
  }).then((res) => res.data);
};

export const createTestsFromSample = (tableid, data) => {
  return request('/api/constructions/' + tableid + '/createtestsfromsample/', {
    data,
    method: 'post',
  });
};

export const finishActiveRow = (tableid, rowid, data) => {
  return request(
    '/api/constructions/' + tableid + '/entries/' + rowid + '/?finish=1',
    {
      data,
      method: 'put',
    }
  ).then((res) => res.data);
};

export const getMoreTableRows = (url) => {
  return request(url).then((res) => res.data);
};

export const getContractorDataSource = (id, query) => {
  return request('/api/constructions/' + id + '/contractors?q=' + query);
};

export const addConstructionEntry = (id, data) => {
  return request(`/api/constructions/${id}/add`, {
    data,
    method: 'post',
  });
};

export const confirmProtocol = (id, protocol_id) => {
  return request(`/api/constructions/${id}/confirm-protocol/${protocol_id}`, {
    method: 'post',
  });
};

export const denyProtocol = (id, protocol_id, denialReason) => {
  return request(`/api/constructions/${id}/deny-protocol/${protocol_id}`, {
    data: { denialReason },
    method: 'post',
  });
};

export const importData = (id, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return request(`/api/constructions/${id}/import-data`, {
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'post',
  });
};

export const importProtocols = (id, files) => {
  const fileList = [];

  for (let i = 0; i < files.length; i++) {
    const targetFile = files[i];

    if (targetFile.name.toLowerCase().indexOf('.pdf') === -1) {
      return Promise.reject({
        response: {
          data: 'Všechny protokoly musí mít příponu .PDF',
        },
      });
    }

    fileList.push(files[i]);
  }

  return Promise.all(
    fileList.map((file) => {
      const formData = new FormData();
      formData.append('file', file);

      return request(`/api/constructions/${id}/import-protocol`, {
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
      });
    })
  );
};
